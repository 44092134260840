import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Alert, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesCollectionDolls: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Collection Items</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_dolls.jpg"
            alt="Liberation"
          />
        </div>
        <div className="page-details">
          <h1>Collection Items - Upgrade Optimization</h1>
          <h2>
            Guide for the Collection Items system in NIKKE showcasing the doll
            maintenance optimization strategy.
          </h2>
          <p>
            Last updated: <strong>09/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          As a new feature, Collection Item (also known as Dolls) is still quite
          mysterious to a lot of people. Sparse maintenance kits and an endless
          array of things to upgrade; how to exactly use Dolls to your advantage
          in the most correct way? Well, in our book, there is no such thing as
          "100% correct", but we can give you some suggestions based on our
          computation.
        </p>
        <Alert variant="primary">
          <p>
            This guide has been created by <strong>kisenix</strong>. If you have
            any feedback about the guide, catch him on our Discord! All the
            tables featured in this guide are compiled by discord user
            <strong>lorax2233</strong>, one of the most valued contributors in
            the Nikke Community Discord Server.
          </p>
        </Alert>
        <SectionHeader title="EXP & Super Success Table" />
        <StaticImage
          src="../../../images/nikke/collection/doll_1.webp"
          alt="Guide"
        />
        <p>
          Before we march forward, let's clarify several facts, starting from
          how many EXP each type of Maintenance Kit gives:
        </p>
        <ul>
          <li>
            Blue Maintenance Kit ×10 grants <strong>200 EXP</strong>.
          </li>
          <li>
            Purple Maintenance Kit ×10 grants <strong>500 EXP</strong>.
          </li>
          <li>
            Gold Maintenance Kit ×10 grants <strong>1000 EXP</strong>.
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/collection/doll_2.webp"
          alt="Guide"
        />
        <p>We also want to know how much EXP is needed for each Doll level:</p>
        <ul>
          <li>
            <strong className="rarity-R">R</strong> Doll (Blue) needs{' '}
            <strong>1000 EXP</strong> per level.
          </li>
          <li>
            <strong className="rarity-SR">SR</strong> Doll (Purple) needs{' '}
            <strong>3000 EXP</strong> per level.
          </li>
        </ul>
        <p>
          However, this is not as important as knowing the Super Success rate
          for each type of Maintenance Kit for different levels of both Doll
          rarities:
        </p>
        <StaticImage
          src="../../../images/nikke/collection/doll_3.webp"
          alt="Guide"
        />
        <p>
          With some maths, we can conclude that EXP serves as a pity, and we
          need to rely on Super% to advance your Doll to the next maintenance
          phase. For example, if you enter higher levels in a phase (due to bad
          luck), the chance of Super% increases. Phase is a group of 5 levels (0
          ➔ 5, 5 ➔ 10, 10 ➔ 15). At the end of each phase, skills are upgraded,
          and the potency of that Doll jumps noticeably.
        </p>
        <p>
          That aside, if you pay close attention, you will realize that certain
          Maintenance Kit rarities perform better than the others! That's what
          we are going to discuss today.
        </p>
        <SectionHeader title="The Resource Efficiency Approach" />
        <p>
          The first thing that comes to mind when studying this fact is to
          evaluate the efficiency of different Maintenance Kit rarities for
          different targets, based on their Super%. Let's say Purple has the
          highest efficiency for R Doll Level 1 ➔ 5, how much would my loss be
          if I used Blue or Gold instead? That's where the table from the
          previous section becomes handy, but this time, let's expand it
          further. We add a new column that calculates the{' '}
          <strong>
            {' '}
            efficiency of a specific rarity against the MAX efficiency available
            for that level.
          </strong>
        </p>
        <StaticImage
          src="../../../images/nikke/collection/doll_4.webp"
          alt="Guide"
        />
        <p>
          The key takeaway here is that Blue Maintenance Kits have inherently
          worse Super% than the other two options.{' '}
          <strong>
            The efficiency of lower rarity kits also plunges as the phase
            increases with higher rarity dolls.
          </strong>{' '}
          If we transform the enormous table above into a short list, it would
          be:
        </p>
        <blockquote>
          <ul>
            <li>
              <strong className="rarity-R">R</strong> DOLL:
            </li>
            <ul>
              <li>1 ➔ 5: Purple &gt; Gold &gt; Blue</li>
              <li>5 ➔ 10: Purple &gt; Gold &gt; Blue</li>
              <li>10 ➔ 15: Gold &gt; Purple &gt; Blue</li>
            </ul>
            <li>
              <strong className="rarity-SR">SR</strong> DOLL:
            </li>
            <ul>
              <li>1 ➔ 5: Gold &gt; Purple &gt; Blue</li>
              <li>5 ➔ 10: Gold &gt; Purple &gt; Blue</li>
              <li>10 ➔ 15: Gold &gt; Purple &gt; Blue</li>
            </ul>
          </ul>
        </blockquote>
        <SectionHeader title="The Average Luck Approach" />
        <p>
          If we have a 4% chance of pulling an SSR, we can theorize that on
          average a player acquires 1 SSR every 25 pulls. This is the average
          luck, and we can implement a similar idea here. We have the{' '}
          <strong>"100% Probability Target"</strong>, which is the average point
          where people hit Super% aka the mode. If you hit Super% before 100%,
          you are considered lucky, and vice versa. However, probability follows
          the Binomial Distribution graph, so your mileage may vary.
        </p>
        <p>
          Now, if you try to add up the probability of the highest efficiency
          rarity over and over again (while keeping byproduct level upgrades in
          mind), you will notice that it exceeds 100% and even spills beyond
          &gt;110%. This is no good, as you are traversing the "unlucky"
          hemisphere without knowing whether you are unlucky. So, the solution
          is to <strong>find a combination as close as to 100%</strong>. You
          would get something like this:
        </p>
        <blockquote>
          <p>
            Here the ( ) denotes to the phase of the Doll, whereas [ ] denotes
            the summed probability. Keep the percentage as close as possible to
            100%, but anything that costs less EXP and has a percentage of
            &gt;100% might be more beneficial in the worst cases. Do keep in
            mind that these upgrades are performed in order from left to right
            because we utilize level ups to boost the chances!
          </p>
          <ul>
            <li>
              <strong className="rarity-R">R</strong> (0 ➔ 5):
            </li>
            <ul>
              <li>2× Purple [110%]</li>
              <li>1× Purple + 3× Blue [107.8%]</li>
              <li>6× Blue [108.8%]</li>
            </ul>
            <li>
              <strong className="rarity-R">R</strong> (5 ➔ 10):
            </li>
            <ul>
              <li>2× Purple [100%]</li>
              <li>6× Blue [99.20%]</li>
            </ul>
            <li>
              <strong className="rarity-R">R</strong> (10 ➔ 15):
            </li>
            <ul>
              <li>1× Gold [Guaranteed]</li>
              <li>2× Purple + 1× Blue [107.6%]</li>
              <li>7× Blue [99.8%]</li>
            </ul>
            <li>
              <strong className="rarity-SR">SR</strong> (0 ➔ 5):
            </li>
            <ul>
              <li>3× Gold + 1× Purple + 1× Blue [100.9%]</li>
            </ul>
            <li>
              <strong className="rarity-SR">SR</strong> (5 ➔ 10):
            </li>
            <ul>
              <li>4× Gold + 1× Purple [102%]</li>
              <li>4× Gold + 3× Blue [99.9%]</li>
            </ul>
            <li>
              <strong className="rarity-SR">SR</strong> (10 ➔ 15):
            </li>
            <ul>
              <li>5× Gold + 1× Purple [99.9%]</li>
              <li>5× Gold + 5× Blue [101%].</li>
            </ul>
          </ul>
        </blockquote>
        <p>
          While this may not be the most accurate proposition, we can use this
          information to deduce how much EXP you need on average to level up
          Dolls from 1 ➔ 15. We also want to point out that EXP barely matters
          here. What's important is hitting that Super%. The reason we also
          emphasized the word "in order" is because we took advantage of the
          Super% increase with levels, so if your Doll is on the fence of
          leveling up, might as well level it up first with Blue/Purple/Gold
          before gambling again.
        </p>
        <p>
          Additionally, we can also conclude that upgrading an R Doll to Level
          15 first, then converting/replacing it with SR Doll (instant Level 5)
          is <strong>more resource-friendly</strong>, even if we include the
          combination feature. However, you lose the R Doll in the process, so
          it might not be a good idea if you are aiming for short-term CP
          padding or combat advantage.
        </p>
        <SectionHeader title="The Smart Allocation Approach" />
        <p>
          In the previous section, we mentioned that Purple & Gold Maintenance
          Kits trump Blue Kits, but while ideally we should avoid them, we
          cannot really avoid them as they are commonly found in Solo Raids and
          Dispatch. So, how do we handle this inevitable inefficiency? By
          balancing our spending so that none of the kits is surplus.
        </p>
        <p>
          Let's begin with how many Maintenance Kits we actually get monthly:
        </p>
        <ul>
          <li>
            Total <strong className="rarity-R">R</strong> kits:{' '}
            <strong>453.78</strong>
          </li>
          <li>
            Total <strong className="rarity-SR">SR</strong> kits:{' '}
            <strong>63.12</strong>
          </li>
          <li>
            Total <strong className="rarity-SSR">SSR</strong> kits:{' '}
            <strong>23.64</strong>
          </li>
          <li>
            Total <strong className="rarity-R">R</strong> dolls:{' '}
            <strong>10.5</strong>
          </li>
          <li>
            Total <strong className="rarity-SR">SR</strong> dolls:{' '}
            <strong>3.87</strong>
          </li>
          <li>
            Total Treasure Items: <strong>150</strong>
          </li>
        </ul>
        <p>
          As you can see, we have way more Blue Maintenance Kits than Purple and
          Gold. If we just blindly follow the Resource Efficiency
          recommendations above, our stash of Purple and Gold{' '}
          <strong>would likely not be able to keep up</strong>! So, how do we
          handle this? We did mention before that lower rarity kits are way
          worse for higher phases, so prioritize Blue for lower phases first!
        </p>
        <p>
          We will skip the minor detail and other calculation as they would be
          too long, but you can view the full version{' '}
          <a
            href="https://discord.com/channels/968096627002851379/1248451493061857312"
            rel="noreferrer"
          >
            here
          </a>{' '}
          in Nikke Community. In short, here's the outcome of this research:
        </p>
        <Alert variant="primary">
          <p>
            If you aim for SR Level 15 Doll, first upgrade R from Level 1 ➔ 15
            with Blue Kits only, then replace the Doll with its SR counterpart.
            After that, upgrade from 5 ➔ 10 using Blue + Purple Kits (Ideally,
            only Blue Kits). As for 10 ➔ 15, use Purple + Gold kits (Ideally,
            only Purple Kits).
          </p>
        </Alert>
        <p>
          Note that this recommendation would only make sense if you are aiming
          for Level 15 SR as it deals with balanced spending/inventory
          management more than efficiency. If you prefer to distribute your
          Dolls to multiple Nikkes evenly for CP padding and short-term Solo
          Raid advantage, it is much better to use the efficiency route or
          whatever you have in the meantime, but it is up to you to decide.
        </p>
        <SectionHeader title="Other guides in the series" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Collection Items - Intro"
            link="/nikke/guides/collection-intro"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_coll.jpg"
                alt="Collection Items - Intro"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Dispatch"
            link="/nikke/guides/collection-dispatch"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_dispatch.jpg"
                alt="Collection Items - Dispatch"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Priority"
            link="/nikke/guides/collection-priority"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_priority.jpg"
                alt="Collection Items - Priority"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesCollectionDolls;

export const Head: React.FC = () => (
  <Seo
    title="Collection Items - Upgrade Optimization | NIKKE | Prydwen Institute"
    description="Guide for the Collection Items system in NIKKE showcasing the doll maintenance optimization strategy."
  />
);
